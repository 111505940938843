<template>
  <div class="d-flex justify-space-between align-center">
    <v-autocomplete
      :items="items"
      item-text="name"
      item-value="identifier"
      v-model="localValue"
      :multiple="!single"
      :placeholder="$t('actions.resources.selectResource')"
      :label="$t('actions.resources.selectResource')"
      chips
      small-chips
      deletable-chips
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.identifier"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-btn
      class="ml-2 align-self-center"
      small
      @click="selectAll"
      text
      color="primary"
    >
      {{ $t("actions.resources.selectAll") }}
    </v-btn>
  </div>
</template>

<script>
import TagRepository from "@/api/repositories/tagRepository.js";
import ActionRepository from "@/api/repositories/actionRepository.js";
import GatewayRepository from "../../api/repositories/gatewayRepository";
import {
  ActionResourceTypes,
  getResourceTypeByEventType,
  NodeActionTypes,
  GatewayActionTypes,
} from "@/_helpers/actionEvents";

export default {
  name: "ResourceSelector",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    single: {
      type: Boolean,
      default: false,
    },
    eventType: {
      type: Number,
      required: true,
    },
  },

  model: { prop: "value", event: "input" },

  data() {
    return {
      items: [],
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    async fetchItems() {
      let resourceType = getResourceTypeByEventType(this.eventType, true);

      switch (resourceType) {
        case ActionResourceTypes.Node: {
          let tags = await TagRepository.getByCurrentCompany();
          return tags.data.map((tag) => ({
            name: tag.name,
            identifier: tag.deveui,
            resourceType: ActionResourceTypes.Node,
          }));
        }
        case ActionResourceTypes.Action: {
          let actions = await ActionRepository.getActions();
          return actions.data.map((action) => ({
            name: action.title,
            identifier: action.actionId,
            resourceType: ActionResourceTypes.Action,
          }));
        }
        case ActionResourceTypes.Gateway: {
          let gateways = await GatewayRepository.getByCurrentCompany();
          return gateways.data.map((gateway) => ({
            name: gateway.name,
            identifier: gateway.macAddress,
            resourceType: ActionResourceTypes.Gateway,
          }));
        }
        case ActionResourceTypes.NodeAction: {
          let actions = await ActionRepository.getActions();
          return actions.data
            .filter(
              (action) =>
                NodeActionTypes.includes(action.eventType) &&
                action.eventType != this.eventType
            )
            .map((action) => ({
              name: action.title,
              identifier: action.actionId,
              resourceType: ActionResourceTypes.Action,
            }));
        }
        case ActionResourceTypes.GatewayAction: {
          let actions = await ActionRepository.getActions();
          return actions.data
            .filter(
              (action) =>
                GatewayActionTypes.includes(action.eventType) &&
                action.eventType != this.eventType
            )
            .map((action) => ({
              name: action.title,
              identifier: action.actionId,
              resourceType: ActionResourceTypes.Action,
            }));
        }

        default:
          throw new Error("Unknown resource type");
      }
    },

    selectAll() {
      this.localValue = this.items.map((item) => item.identifier);
    },
  },

  async created() {
    this.items = await this.fetchItems();
  },

  watch: {
    eventType: {
      handler: async function () {
        this.items = await this.fetchItems();
      },
      immediate: true,
    },
  },
};
</script>
